import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import SimpleModernHeadline from '../../components/SimpleModernHeadline/SimpleModernHeadline';
import HeadlineWithImage from '../../components/HeadlineWithImage/HeadlineWithImage';

import img_leistung_24 from '../../images/leistungen/img24.jpg';

const LeistungenPage = () => (
  <Layout headerWhite>
    <SEO title="Mesohair bei Haarausfall | Dr. K. Kostka in Bochum" description="Dr. K. Kostka bietet professionelle  Mesohair - PRP Behandlungen gegen Haarausfall mitten in Bochum. Moderne Praxis ✓ Top Ausstattung ✓ Vereinbaren Sie jetzt einen Termin ✓"/>
    <Section>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12}>
            <HeadlineWithImage headline={"Haarausfall"} subtitle={"Mesohair - PRP"} frontImage={img_leistung_24}></HeadlineWithImage>
          </Col>
        </Row>
        <p>
          Haarverlust ist bei Frauen und Männern in einem begrenzten Umfang ein natürlicher Prozess. Bei übermäßigem Haarausfall oder Haarverlust stellen sowohl die Mesotherapie als auch das PRP Verfahren hervorragende Behandlungsoptionen dar, um den Prozess aufzuhalten bzw. zu stoppen und Haarwachstum anzuregen.
          <br/>
          Diese Therapie ist insbesondere nach einer Haartransplantation besonders effektiv, da sie das Anwachsen der Haarfollikel fördert. Haartransplantationszentren empfehlen diese Behandlung.
          <br/>
          Die Behandlung ist für Männer und Frauen mit hormonellem oder erblich bedingtem Haarausfall gleich gut geeignet, unabhängig vom Alter.
          <br/>
          <br/>
          Bei der Mesohair Therapie werden initial 3-4 Behandlungen im wöchentlichen Rhythmus benötigt, anschließend monatliche Auffrischungen. Erste Erfolge zeigen sich in der Regel nach 5 Behandlungen, Haarwachstum frühestens nach 6-8 Monaten.
          <br/>
          <br/>
          Die PRP Therapie umfasst 4 Behandlungen: die ersten 3 mit jeweils 4 wöchigem Abstand und danach alle 6 Monate. Ergebnisse sind nach 5 Monaten sichtbar.
          <br/>
          <br/>
          Beide Therapieverfahren  -  Mesohair und PRP  -  sind Dauerbehandlungen und müssen, will man den Effekt erhalten, regelmäßig aufgefrischt werden. 
          <br/>
          <br/>
          Medizinische Studien belegen, dass bei beiden Therapieverfahren die Erfolgsquote im Kampf gegen den Haarausfall dann bei 80% liegt. 
          <br/>
          Sollte ich Ihr Interesse geweckt haben, vereinbaren Sie gerne einen Termin in meiner Praxis in Bochum.
        </p>
      </Container>
    </Section>
  </Layout>
);

export default LeistungenPage;